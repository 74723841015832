import React from "react";
import clsx from "clsx";
import Img from "gatsby-image";
import { Link } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { CommonJumbotron } from "../../components/jumbotron";
import ContainerBox from "../../components/containerBox";
import { SlidingTypography } from "../../components/sliding";
import { Arrow } from "../../components/icons";
import { pageContextType } from "../../pageContextType";

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      paddingTop: "80px",
      paddingBottom: "80px",
    },
    section: {
      paddingTop: "80px",
      paddingBottom: "80px",
    },
    flexRight: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    flexLeft: {
      display: "flex",
      flexDirection: "row",
    },
    title: {
      color: "#222222",
      fontSize: "40px",
      fontWeight: 700,
      marginBottom: "20px",
      lineHeight: "1.3",
      maxWidth: "670px",
      wordBreak: "keep-all",
    },
    description: {
      color: "#858585",
      fontSize: "16px",
      marginBottom: "30px",
      maxWidth: "670px",

      "&:last-child": {
        marginBottom: 0,
      },
    },
    sectionImage: {
      height: "360px",
      objectFit: "cover",
      marginBottom: "80px",
    },
    learnMore: {
      color: "#00dd99",
      fontSize: "14px",
      textDecoration: "none",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",

      "& > svg": {
        fontSize: "14px",
        marginLeft: "4px",
      },

      "&:hover": {
        color: "#b4b4b4",
      },
    },
  })
);

function TechPage({ pageContext }: pageContextType) {
  const classes = useStyles();
  const { language } = pageContext;
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query {
      backgroundPlaceholder: file(
        relativePath: { eq: "tech/img-title-photo-tech-main-01@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      techPlaceholder1: file(relativePath: { eq: "tech/img-tech-01@3x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      techPlaceholder2: file(relativePath: { eq: "tech/img-tech-02@3x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      techPlaceholder3: file(relativePath: { eq: "tech/img-tech-03@3x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      techPlaceholder4: file(relativePath: { eq: "tech/img-tech-04@3x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout language={language}>
      <SEO
        title="Tech"
        description={intl.formatMessage({ id: "tech_description" })}
      />
      <CommonJumbotron
        backgroundImageFluid={data.backgroundPlaceholder.childImageSharp.fluid}
        title={intl.formatMessage({ id: "tech_title" })}
        lead={intl.formatMessage({ id: "tech_description" })}
      />

      <main className={classes.content}>
        <ContainerBox className={clsx(classes.section, classes.flexLeft)}>
          <div>
            <SlidingTypography variant="h2" className={classes.title}>
              WebRTC
            </SlidingTypography>
            <SlidingTypography component="p" className={classes.description}>
              {intl.formatMessage({ id: "webrtc_lead" })}
            </SlidingTypography>
            <SlidingTypography>
              <Link to="/tech/webrtc" className={classes.learnMore}>
                <span>{intl.formatMessage({ id: "learn_more_rtc" })}</span>
                <Arrow />
              </Link>
            </SlidingTypography>
          </div>
        </ContainerBox>

        <ContainerBox leftOnly>
          <Img
            fluid={data.techPlaceholder1.childImageSharp.fluid}
            className={classes.sectionImage}
            alt={intl.formatMessage({ id: "webrtc_title_1" })}
          />
        </ContainerBox>

        <ContainerBox className={clsx(classes.section, classes.flexRight)}>
          <div>
            <SlidingTypography variant="h2" className={classes.title}>
              AI
            </SlidingTypography>
            <SlidingTypography component="p" className={classes.description}>
              {intl.formatMessage({ id: "ai_lead" })}
            </SlidingTypography>
            <SlidingTypography>
              <Link to="/tech/aiml" className={classes.learnMore}>
                <span>{intl.formatMessage({ id: "learn_more_ai" })}</span>
                <Arrow />
              </Link>
            </SlidingTypography>
          </div>
        </ContainerBox>

        <ContainerBox rightOnly>
          <Img
            fluid={data.techPlaceholder2.childImageSharp.fluid}
            className={classes.sectionImage}
            alt={intl.formatMessage({ id: "webrtc_ai_1" })}
          />
        </ContainerBox>

        <ContainerBox className={clsx(classes.section, classes.flexLeft)}>
          <div>
            <SlidingTypography variant="h2" className={classes.title}>
              Data
            </SlidingTypography>
            <SlidingTypography component="p" className={classes.description}>
              {intl.formatMessage({ id: "data_lead" })}
            </SlidingTypography>
            <SlidingTypography>
              <Link to="/tech/data" className={classes.learnMore}>
                <span>{intl.formatMessage({ id: "learn_more_data" })}</span>
                <Arrow />
              </Link>
            </SlidingTypography>
          </div>
        </ContainerBox>

        <ContainerBox leftOnly>
          <Img
            fluid={data.techPlaceholder4.childImageSharp.fluid}
            className={classes.sectionImage}
            alt={intl.formatMessage({ id: "webrtc_data_1" })}
          />
        </ContainerBox>
      </main>
    </Layout>
  );
}

export default TechPage;
