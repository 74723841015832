import React from "react";
import clsx from 'clsx';
import Typography, { TypographyProps } from "@material-ui/core/Typography";

function SlidingTypography (props: TypographyProps & {component? : React.ElementType}){
  return (
    <Typography
      {...props}
      className={clsx(props.className, 'reveal')}
      // data-sal="slide-up"
      // data-sal-delay="200"
      // data-sal-easing="ease"
    >
      {props.children}
    </Typography>
  );
};

export default SlidingTypography;
