import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BackgroundImage, { IFluidObject } from "gatsby-background-image";

import { useBreadcrumb } from "../../hooks";
import ContainerBox from "../containerBox";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "720px",
      backgroundSize: "cover",
      background: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )",
      backgroundPosition: "center center",
      display: "flex",
      alignItems: "center",
    },
    container: {
      wordBreak: "keep-all",
    },
    head: {
      color: "white",
      fontFamily: ["Poppins", "Pretendard", "system-ui", "sans-serif"].join(
        ","
      ),
      fontWeight: 700,
      lineHeight: "1.2",
      maxWidth: "700px",
      fontSize: "62px",
      [theme.breakpoints.up("xs")]: {
        fontSize: "40px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "50px",
      },
    },
    lead: {
      fontSize: "22px",
      color: "white",
      fontWeight: 400,
      maxWidth: "700px",
    },
    breadcrumb: {
      fontSize: "16px",
      color: "#fff",
      marginBottom: "30px",
      maxWidth: "580px",
    },
  })
);

interface CommonJumbotronProps {
  title: string;
  lead?: string;
  backgroundImageFluid:
    | IFluidObject
    | IFluidObject[]
    | (IFluidObject | string)[];
}

const CommonJumbotron: React.FC<CommonJumbotronProps> = ({
  backgroundImageFluid,
  title,
  lead = "",
}) => {
  const classes = useStyles();
  const breadcrumb = useBreadcrumb();

  return (
    <BackgroundImage
      key={uuidv4()}
      Tag="section"
      className={classes.root}
      fluid={backgroundImageFluid}
    >
      <ContainerBox className={classes.container}>
        <Typography className={classes.breadcrumb}>{breadcrumb}</Typography>
        <h1
          className={classes.head}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Typography className={classes.lead} component="p" gutterBottom>
          {lead}
        </Typography>
      </ContainerBox>
    </BackgroundImage>
  );
};

CommonJumbotron.defaultProps = {
  lead: "",
};

export default CommonJumbotron;
