import React from "react";
import clsx from 'clsx';

interface SlidingDivProps {
  className?: string;
  children: React.ReactNode;
}

function SlidingDiv({ className, children }: SlidingDivProps) {
  return (
    <div
      className={clsx(className, 'reveal')}
      // data-sal="slide-up"
      // data-sal-delay="200"
      // data-sal-easing="ease"
    >
      {children}
    </div>
  );
};

export default SlidingDiv;
